body,
html,
p,
h1,
h2,
h3 {
  margin: 0;
  padding: 0;
}

body {
  background-color: #4db6ea;
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none; /* Likely future */
}

.App {
  text-align: center;
  font-family: "Roboto", sans-serif;
}

.App-logo {
  height: 100px;
  pointer-events: none;
  color: #06222f;
  margin-bottom: 40px;
}

h1 {
  font-size: 48px;
}

h2 {
  font-size: 30px;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #06222f;

  animation-duration: 5s;
  animation-name: fadeInOut;
  animation-iteration-count: 1;
  animation-timing-function: ease-out;
}

@keyframes fadeInOut {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
